<template>
  <div class="pageBox">
    <div class="pageBox_right">
      <span class="page_txt">Rows per page:</span>
      <el-select v-model="sizePage" class="size_page" @change="selectCurrentItem">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page" :page-size="pageSize" layout="prev, next" :total="total"> </el-pagination>
  
    </div>
  </div>
</template>
<script>
import bus from './bus'
export default {
  props: {
    totalFont: {
      type: Boolean,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      //page: this.currentPage
      options: [
      
        {
          value: 10,
          label: 10,
        },
        {
          value: 15,
          label: 15,
        },
        {
          value: 20,
          label: 20,
        },
      ],
      sizePage: 10,
      token: localStorage.getItem('token'),
    }
  },
  
  computed: {
    page: {
      get() {
        return this.currentPage
      },
      set() {},
    },
    isPagin: {
      get() {
        let status=localStorage.getItem('token')?false:true
        return status
      },
      set() {},
    },
  },
  destroyed(){
    // bus.$off('handleShowLogin');
  },


  methods: {
    handleCurrentChange(val) {
      this.token=localStorage.getItem('token')
      if (!this.token) {
        bus.$emit('handleShowLogin', true) //是否显示登录
        this.page = 1
        this.$emit('onPageChange', 1)
      } else {
        this.$emit('onPageChange', val)
      }
    },
    // onFirst() {
    //   this.page = 1
    //   this.handleCurrentChange(1)
    // },
    // onLast() {
    //   const page = Math.ceil(this.total / this.pageSize)
    //   this.page = page
    //   this.handleCurrentChange(page)
    // },

    //切换一页显示几条
    selectCurrentItem() {
   
      this.token=localStorage.getItem('token')
      // this.$emit('onPageChange', this.sizePage)
      if (this.token) {

        this.$emit('currtentPageChange', this.sizePage)
      } else {
        bus.$emit('handleShowLogin', true) //是否显示登录
        this.page = 1
        return false
      }
    },


  },
}
</script>
<style scoped>
.totalFont {
  line-height: 18px;
  font-size: 14px;
  color: #8398bb;
}
.pageBox {
  display: flex;
  justify-content:flex-end;
  margin-top: 2px;
  height: 40px;
  background: #f2f5f9;
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #e7eaed;
}
.pageBox_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
}
.page_txt {
  font-size: 12px;
  color: #8497ab;
  line-height: 14px;
  margin-right: 6px;
}
.size_page {
  margin-right: 20px;
}
.size_page /deep/ .el-input__inner {
  width: 50px;
  height: 26px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e7eaed;
  padding: 0 8px;
  color: #1290c9;
  font-size: 12px;
  line-height: 26px;
}
.size_page /deep/ .el-icon-arrow-up:before {
  color: #8497ab;
  font-size: 12px;
}
.el-select-dropdown__item {
  font-size: 12px;
}
.size_page /deep/ .el-input__icon {
  width: 20px;
  line-height: 24px;
}
.firstBtn,
.lastBtn {
  width: 26px;
  height: 26px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e7eaed;
}

button img {
  width: 10px;
  vertical-align: middle;
}
/* 分页 */
.pageBox /deep/ .el-pagination button,
.pageBox /deep/ .el-pagination button span:not([class*='suffix']) {
  display: inline;
  min-width: 26px !important;
  height: 26px;
  line-height: 24px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e7eaed;
}

.pageBox /deep/ .btn-prev {
  margin-right: 10px;
  padding-right: 6px;
}
.pageBox /deep/ .btn-next {
  padding-left: 6px;
}
.pageBox /deep/ .el-icon-arrow-right:before,
.pageBox /deep/ .el-icon-arrow-left:before {
  content: '';
}
.pageBox /deep/ .el-icon-arrow-right,
.pageBox /deep/ .el-icon-arrow-left {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  background-position: center;
}
.pageBox /deep/ .el-icon-arrow-left {
  background-image: url(../assets/img/search/pre_ic1.png);
}
.pageBox /deep/ button:disabled .el-icon-arrow-left {
  background-image: url(../assets/img/search/pre_ic2.png);
}
.pageBox /deep/ .el-icon-arrow-right {
  background-image: url(../assets/img/search/next_ic1.png);
}
.pageBox /deep/ button:disabled .el-icon-arrow-right {
  background-image: url(../assets/img/search/next_ic2.png);
}
</style>
